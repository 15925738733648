import { db, firestore } from "../../firebase";

const { collection, addDoc, Timestamp, updateDoc, query, where, getDocs, doc } =
  firestore;

const usersRef = collection(db, "users");

export const adduser = async (user: any) => {
  try {
    await addDoc(usersRef, {
      ...user,
      qrs: [],
      completed: false,
      created: Timestamp.now(),
    });
  } catch (err) {
    console.log(err);
  }
};

export const findUserByEmail = async (_email: string) => {
  return getDocs(query(usersRef, where("email", "==", _email)));
};

export const updateCompletedStatus = async (_email: string) => {
  try {
    const users = findUserByEmail(_email);

    (await users).forEach(async (_u) => {
      const _userRef = doc(db, "users", _u?.id);

      await updateDoc(_userRef, {
        completed: true,
      });
    });
  } catch (error) {}
};
