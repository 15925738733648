import React, { useEffect } from "react";
import logo from "./logo.svg";
import "./App.css";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "./theme";
import { AppContext, AppContextProvider, GAME_STAGES_ENUM } from "./context";
import FlashScreen from "./screens/flash";
import QuestionsScreen from "./screens/questions";
import GameCompleted from "./screens/game-completed";

function App() {
  useEffect(() => {
    if (window.location.href.includes("reset")) {
      window.localStorage.clear();
      window.location.href = "/";
    }
  }, []);

  return (
    <ChakraProvider theme={theme}>
      <AppContextProvider>
        <Main />
      </AppContextProvider>
    </ChakraProvider>
  );
}

export default App;

const Main = () => {
  const ctx = React.useContext(AppContext);

  const renderState = () => {
    switch (ctx?.state?.stage) {
      case GAME_STAGES_ENUM.PLAYING:
        return <QuestionsScreen />;

      case GAME_STAGES_ENUM.COMPLETED:
        return <GameCompleted />;

      case GAME_STAGES_ENUM.NOT_STARTED:
        return <FlashScreen />;

      default:
        return <FlashScreen />;
    }
  };

  return <>{renderState()}</>;
};
