import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBJF57TpDR_OhqXa-RHgcNDQQAFvVMN9cM",
  authDomain: "qr-project-39208.firebaseapp.com",
  projectId: "qr-project-39208",
  storageBucket: "qr-project-39208.appspot.com",
  messagingSenderId: "278535489204",
  appId: "1:278535489204:web:2e99fdedfad7c424b039e1",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db };

export * as firestore from "firebase/firestore";
