import { APP } from "../../config";

import Cookies from "js-cookie";

type key = "APP_STATE" | "APP_USER_INFO";

const SET_TO_LOCAL_STORAGE = (_key: key, value: any) => {
  const name = `${APP.VERSION}-${_key}`;
  if (isLocalStorageAvailable()) {
    localStorage.setItem(name, value);
    return;
  }

  Cookies.set(name, value, { path: "/" });
};

const GET_FROM_LOCAL_STPRAGE = (_key: key) => {
  const name = `${APP.VERSION}-${_key}`;

  if (isLocalStorageAvailable()) {
    return JSON.parse(localStorage.getItem(`${APP.VERSION}-${_key}`) ?? "null");
  }

  return JSON.parse(Cookies.get(name) ?? "null");
};

export { SET_TO_LOCAL_STORAGE, GET_FROM_LOCAL_STPRAGE };

const isLocalStorageAvailable = () => {
  try {
    const mod = "asd";

    localStorage.setItem(mod, mod);
    localStorage.removeItem(mod);

    return true;
  } catch (e) {
    return false;
  }
};
