import React, { useEffect, useState } from "react";
import { updateCompletedStatus } from "../services/database";
import {
  GET_FROM_LOCAL_STPRAGE,
  SET_TO_LOCAL_STORAGE,
} from "../services/local-storage";

export enum GAME_STAGES_ENUM {
  "NOT_STARTED",
  "PLAYING",
  "COMPLETED",
}

interface IQuestion {
  title: string;
  key: string;
  stallNo: string;
  isOptional?: boolean;
  isVisited?: boolean;
}

export const QrsArray = [
  {
    title: "QXR",
    key: "qxr",
    isOptional: true,
    stallNo: "123",
    isVisited: false,
  },
  {
    title: "KBC",
    key: "kbc",
    isOptional: false,
    stallNo: "123",
    isVisited: false,
  },
  {
    title: "VR",
    key: "vr",
    isOptional: false,
    stallNo: "123",
    isVisited: false,
  },
  {
    title: "360 photobooth",
    key: "360",
    isOptional: false,
    stallNo: "123",
    isVisited: false,
  },
  {
    title: "Taj Mahal Photobooth",
    key: "taj",
    isOptional: false,
    stallNo: "123",
    isVisited: false,
  },
];

const AppContext = React.createContext(
  {} as unknown as { state: IInitialState; setState: (st: any) => void }
);

const AppContextConsumer = AppContext.Consumer;

export interface IInitialState {
  stage: GAME_STAGES_ENUM;
  questions: IQuestion[];
}

const getInitialState = () => {
  const st: IInitialState = GET_FROM_LOCAL_STPRAGE("APP_STATE")
    ? GET_FROM_LOCAL_STPRAGE("APP_STATE")
    : {
        stage: GAME_STAGES_ENUM.NOT_STARTED,
        questions: QrsArray,
      };

  return st;
};

const AppContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [state, setState] = useState(getInitialState());

  useEffect(() => {
    SET_TO_LOCAL_STORAGE("APP_STATE", JSON.stringify(state));

    //CHECK IF GAME IS COMPLETED
  }, [state]);

  const checkIFGameIsCompleted = async () => {
    if (state?.stage === GAME_STAGES_ENUM.PLAYING) {
      const incompleteQR = state.questions.find((_question) => {
        if (!_question?.isVisited && !_question?.isOptional) {
          return true;
        }

        return false;
      });

      setTimeout(async () => {
        if (!incompleteQR) {
          setState((st) => {
            return {
              ...st,
              stage: GAME_STAGES_ENUM.COMPLETED,
            };
          });

          try {
            const u = GET_FROM_LOCAL_STPRAGE("APP_USER_INFO");

            if (u?.email) {
              const result = await updateCompletedStatus(u?.email);
            }
          } catch (error) {}
        }
      }, 40);
    }
  };

  useEffect(() => {
    checkIFGameIsCompleted();
  });

  return (
    <AppContext.Provider
      value={{
        state,
        setState,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export { AppContext, AppContextConsumer, AppContextProvider };
