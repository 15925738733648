import { extendTheme, type ThemeConfig } from "@chakra-ui/react";

const theme: ThemeConfig = extendTheme({
  colors: {
    primary: {
      "50": "#EDEDF8",
      "100": "#CDCBEB",
      "200": "#ACAADF",
      "300": "#8C89D2",
      "400": "#6B67C6",
      "500": "#2D2A6F",
      "600": "#3C3894",
      "700": "#2D2A6F",
      "800": "#1E1C4A",
      "900": "#0F0E25",
    },
  },
  fonts: {
    heading: "'Yeseva One', cursive",
    body: "'Josefin Sans', sans-serif",
  },
  initialColorMode: "light",
  useSystemColorMode: false,
  components: {
    Text: {
      baseStyle: {
        color: "#131313",
      },
    },
  },
});

export default theme;
