import React from "react";
import { Box, Flex, Text, Image, ComponentStyleConfig } from "@chakra-ui/react";

const Layout = ({
  children,
  hideLogo,
}: {
  children: React.ReactNode;
  hideLogo?: boolean;
}) => {
  return (
    <>
      <Flex
        width="100%"
        maxWidth={["100vw", "100vw", "600px", "600px"]}
        mx="auto"
        height="100%"
        minHeight="100vh"
        overflowY="auto"
        direction="column"
        bg="white"
      >
        {hideLogo ? <></> : <Header />}

        {children}
      </Flex>
    </>
  );
};

export default Layout;

const Header = () => {
  return (
    <Box p={4} textAlign="center">
      <Image src="/images/Viatris Logo.png" height="65px" mx="auto" />
    </Box>
  );
};
