import { Grid, Button } from "@chakra-ui/react";
import { Input } from "./input-with-error";
import { Formik } from "formik";

const validateEmail = (email: string) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

const validateForm = ({
  name,
  email,
  contact,
  designation,
  organization,
}: {
  name?: string;
  email?: string;
  contact?: string;
  designation?: string;
  organization?: string;
}) => {
  let errors: any = {};

  if (!name || !name?.trim()?.length) {
    errors["name"] = "Name is required";
  }

  if (!designation || !designation?.trim()?.length) {
    errors["designation"] = "Designation is required";
  }

  if (!organization || !organization?.trim()?.length) {
    errors["organization"] = "Organization is required";
  }

  if (!contact || !contact?.trim()?.length) {
    errors["contact"] = "Contact number is required";
  }

  if (!email || !email?.trim()?.length) {
    errors["email"] = "Email Address is required";
  } else if (!validateEmail(email.trim())) {
    errors["email"] = "Email address has to be valid";
  }

  return errors;
};

export const UserInfoForm = ({
  formSubmitAction,
}: {
  formSubmitAction: (values: any, action: any) => void;
}) => {
  return (
    <>
      <Formik
        initialValues={{
          name: "",
          email: "",
          contact: "",
          designation: "",
          organization: "",
        }}
        validate={validateForm}
        onSubmit={formSubmitAction}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          /* and other goodies */
        }) => (
          <form onSubmit={handleSubmit}>
            <Grid gap={6} width="100%">
              <Input
                variant="flushed"
                colorScheme="primary"
                placeholder="Name"
                name="name"
                value={values?.name}
                onChange={handleChange}
                onBlur={handleBlur}
                errorMessage={
                  errors.name && touched.name && errors.name ? errors?.name : ""
                }
              />
              <Input
                variant="flushed"
                colorScheme="primary"
                placeholder="Contact number"
                name="contact"
                value={values?.contact}
                onChange={handleChange}
                onBlur={handleBlur}
                errorMessage={
                  errors.contact && touched.contact && errors.contact
                    ? errors?.contact
                    : ""
                }
              />
              <Input
                variant="flushed"
                colorScheme="primary"
                placeholder="Email"
                name="email"
                value={values?.email}
                onChange={handleChange}
                onBlur={handleBlur}
                errorMessage={
                  errors.email && touched.email && errors.email
                    ? errors?.email
                    : ""
                }
              />

              <Input
                variant="flushed"
                colorScheme="primary"
                placeholder="Designation"
                name="designation"
                value={values?.designation}
                onChange={handleChange}
                onBlur={handleBlur}
                errorMessage={
                  errors.designation &&
                  touched.designation &&
                  errors.designation
                    ? errors?.designation
                    : ""
                }
              />

              <Input
                variant="flushed"
                colorScheme="primary"
                placeholder="Organization"
                name="organization"
                value={values?.organization}
                onChange={handleChange}
                onBlur={handleBlur}
                errorMessage={
                  errors.organization &&
                  touched.organization &&
                  errors.organization
                    ? errors?.organization
                    : ""
                }
              />

              <Button
                size="lg"
                width="100%"
                colorScheme="primary"
                type="submit"
                isLoading={isSubmitting}
              >
                Start The Game
              </Button>
            </Grid>
          </form>
        )}
      </Formik>
    </>
  );
};
