import React, { useState, useEffect } from "react";
import { Box, Flex, Text, Image, useToast, Grid } from "@chakra-ui/react";
import { AppContext, IInitialState } from "../context";
import Layout from "../layout";

const QuestionsScreen = () => {
  const ctx = React.useContext(AppContext);

  const toast = useToast();

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    console.log(params);

    if ("qr" in params) {
      const qrRecord = ctx?.state?.questions.find((_qstn) => {
        console.log(_qstn?.key, params?.qr, _qstn.key === params.qr);

        if (_qstn?.key === params?.qr) {
          return true;
        }

        return false;
      });

      if (qrRecord && !qrRecord.isVisited) {
        // mark qr record as visited and uupdated the state
        ctx?.setState((st: IInitialState) => {
          return {
            ...st,
            questions: st.questions.map((_question) => {
              // @ts-ignore
              if (_question.key.toLowerCase() === params.qr.toLowerCase()) {
                return {
                  ..._question,
                  isVisited: true,
                };
              }

              return _question;
            }),
          };
        });

        // show success toast message

        toast({
          title: "Success.",
          description: `${qrRecord.title} QR scanned successfully !`,
          status: "success",
          duration: 4000,
          isClosable: true,
        });
      }
    }
  }, []);

  return (
    <>
      <Layout>
        <Box p={4}>
          <Text fontSize="xl" fontWeight="bold" color="primary.500">
            Hi, <br />
            We request you to visit the following areas and scan the respective
            QR codes.
          </Text>

          <Grid gap={6} my={4}>
            {ctx?.state?.questions.map((qr, index) => (
              <QRCodeBlock
                key={qr?.key}
                position={index + 1}
                title={qr?.title}
                isVisited={qr?.isVisited}
              />
            ))}
            {/* <QRCodeBlock /> */}
          </Grid>
        </Box>
      </Layout>
    </>
  );
};

export default QuestionsScreen;

const QRCodeBlock = ({
  position,
  title,
  isVisited,
}: {
  position: number;
  title: string;
  isVisited?: boolean;
}) => {
  return (
    <Box
      position="relative"
      p={4}
      borderRadius="md"
      border="1px solid"
      borderColor="gray.200"
      px={6}
      bg={isVisited ? "green.50" : "white"}
    >
      <Text fontSize="xl" fontWeight="bold" color="primary.500">
        {title}
      </Text>
      <Text fontSize="sm" color="gray.500">
        {isVisited ? "VISITIED" : " NOT VISITIED"}
      </Text>

      <Flex
        bg={isVisited ? "green.50" : "white"}
        border="1px solid"
        borderColor="gray.200"
        minHeight="25px"
        minWidth="25px"
        height="25px"
        width="25px"
        align="center"
        justify="center"
        borderRadius="50%"
        position="absolute"
        top="50%"
        left="0"
        transform="translate(-50%,-50%)"
      >
        <Text fontSize="sm" color="gray.500">
          {position}
        </Text>
      </Flex>
    </Box>
  );
};
