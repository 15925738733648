import React, { useState, useEffect } from "react";
import { Box, Flex, Text, Image, Progress } from "@chakra-ui/react";
import { AppContext, GAME_STAGES_ENUM } from "../context";
import { useToast } from "@chakra-ui/react";
import { UserInfoForm } from "../components/user-info-form.";
import Layout from "../layout";
import { FormikHelpers } from "formik";
import { SET_TO_LOCAL_STORAGE } from "../services/local-storage";
import { adduser, findUserByEmail } from "../services/database";

interface IFormValues {
  name: string;
  email: string;
  contact: string;
  designation: string;
  organization: string;
}

const FlashScreen = () => {
  const ctx = React.useContext(AppContext);

  const moveToNextStage = () => {
    ctx?.setState((st: any) => {
      return {
        ...st,
        stage: GAME_STAGES_ENUM.PLAYING,
      };
    });
  };

  const onFormSubmit = async (
    values: IFormValues,
    action: FormikHelpers<IFormValues>
  ) => {
    action.setSubmitting(true);

    try {
      const u = await findUserByEmail(values?.email);

      console.log("USERS", u);

      const completedUsers: any[] = [];

      u.forEach((doc) => {
        const _user = doc.data();

        if (!_user?.completed) {
          completedUsers.push({
            id: doc?.id,
            ..._user,
          });
        }
      });

      // if (completedUsers?.length) {
      //   return;
      // }

      const result = await adduser(values);

      SET_TO_LOCAL_STORAGE("APP_USER_INFO", JSON.stringify(values));
      moveToNextStage();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Layout hideLogo={true}>
      <Flex flex={1} width="100%" direction="column">
        <Flex
          direction="column"
          align="center"
          justify="center"
          textAlign="center"
          p={4}
        >
          <Image height="175px" src="/images/TB Logo1.png" />
          <Box textAlign="center" my={6}>
            <Text fontFamily="heading" fontSize="xl" mb={2}>
              Welcome
              <br />
              to
              <br />
              Viatris Faculty Lounge
            </Text>
            <Text>
              Take part in our fight to eradicate TB, fill out the below form,
              scan the QR codes and win amazing gift hamper
            </Text>
          </Box>
        </Flex>

        <Box p={4}>
          <UserInfoForm formSubmitAction={onFormSubmit} />
        </Box>
      </Flex>
    </Layout>
  );
};

export default FlashScreen;
