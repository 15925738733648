import React, { useState, useEffect } from "react";
import {
  Box,
  Flex,
  Text,
  Image,
  Progress,
  Button,
  Grid,
  Input,
} from "@chakra-ui/react";
import { AppContext, GAME_STAGES_ENUM } from "../context";
import { UserInfoForm } from "../components/user-info-form.";
import Layout from "../layout";

const GameCompleted = () => {
  const [value, setValue] = useState(0);
  const ctx = React.useContext(AppContext);

  return (
    <>
      <Layout>
        <Flex
          flex={1}
          width="100%"
          direction="column"
          pt={4}
          justify="space-between"
        >
          <Box />

          <Flex
            direction="column"
            align="center"
            justify="center"
            textAlign="center"
            p={4}
          >
            <Image
              src="/images/GIFT_HAMPER.png"
              mb={4}
              maxWidth="300px"
              width="100%"
            />
            <Box textAlign="center" my={6}>
              <Text fontFamily="heading" fontSize="2xl" mb={2}>
                Congratulations
              </Text>
              <Text>
                You have unlocked a gift hamper! Please collect it from
              </Text>

              <Text fontSize="xl" color="primary.500" fontWeight="bold" my={4}>
                " Viatris Partnership Zone desk "
              </Text>
            </Box>
          </Flex>

          <Box></Box>
        </Flex>
      </Layout>
    </>
  );
};

export default GameCompleted;
