import {
  Input as CInput,
  InputProps as CInputProps,
  Text,
  Box,
} from "@chakra-ui/react";

interface IInputProps extends CInputProps {
  errorMessage?: string;
}

const Input = (props: IInputProps) => {
  const { errorMessage } = props;

  return (
    <Box>
      <CInput {...props} />
      {errorMessage ? (
        <Text fontSize="sm" color="red.500">
          {errorMessage}
        </Text>
      ) : (
        ""
      )}
    </Box>
  );
};

export { Input };
